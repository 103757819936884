import { useDispatch } from 'react-redux'
import { createApiUrl } from './helperFunctions'
import { CHAT_PROJECT_ID, intent, location } from './constants'
import { setLocationsList, setOnlyLocations } from '../redux/actions'

const useGetLocations = (): { fetchGetLocations: () => void } => {
  const dispatch = useDispatch()

  function fetchGetLocations() {
    const payload = {
      intent: intent?.getLocationsAPI,
    }
    fetch(createApiUrl(CHAT_PROJECT_ID), {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('react-token')}`,
        'Content-Type': 'application/json',
        version: 'WORKING',
      },
      body: JSON.stringify(payload),
    })
      .then((res) => {
        if (!res.ok) throw new Error('Failed to get locations')
        return res?.json()
      })
      .then((data) => {
        dispatch(
          setOnlyLocations(
            data?.entry
              ?.filter(
                (item) =>
                  item?.resource?.name === location?.amalapuram ||
                  item?.resource?.name === location?.malkipuram ||
                  item?.resource?.name === location?.rajahmundry ||
                  item?.resource?.name === location?.kimsAmalapuram
              )
              ?.map((location) => {
                return location?.resource?.name
              })
              ?.sort()
          )
        )
        dispatch(
          setLocationsList(
            data?.entry?.map((location) => {
              return {
                id: location?.resource?.id,
                location: location?.resource?.name,
              }
            })
          )
        )
      })
      .catch((err) => console.error(err))
  }

  return { fetchGetLocations }
}

export default useGetLocations
