import { store } from './redux/store'
import { Provider } from 'react-redux'
import { ReactElement, useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Sidebar from './components/Sidebar/Sidebar'
import Navigator from './components/navigation/Navigator'
import './App.css'

const App = (): ReactElement => {
  const navigate = useNavigate()
  const hasMounted = useRef(false)
  const location = useLocation()
  const hiddenSidebarRoutes = ['/videoCall']

  useEffect(() => {
    if (!hasMounted.current) {
      hasMounted.current = true

      if (window.location.pathname !== '/videoCall') {
        navigate('/')
      }
    }
  }, [navigate])

  return (
    <div className='command-center' id='command-center-app'>
      <Provider store={store}>
        {!hiddenSidebarRoutes?.includes(location?.pathname) && <Sidebar />}
        <Navigator />
      </Provider>
    </div>
  )
}

export default App
