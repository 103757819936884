import { MutableRefObject, useState, useRef, ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setLayout } from '../../redux/actions'
import { useTheme } from '@mui/material/styles'
import {
  layout,
  sidebarTexts,
  exitFullscreen,
  enterFullScreen,
  sidebarPatientLayout,
} from '../../utils/constants'
import {
  Box,
  Menu,
  Button,
  Dialog,
  Tooltip,
  MenuItem,
  IconButton,
  DialogTitle,
  useMediaQuery,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material'
import Alerts from '../Alerts/Alerts'
// import HomeIcon from '@mui/icons-material/Home'
import VideocamIcon from '@mui/icons-material/Videocam'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import DashboardIcon from '@mui/icons-material/Dashboard'
import KeycloakService from '../../utils/keycloakService'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import LocalHospitalIcon from '@mui/icons-material/LocalHospital'
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import ThopNotificationAlert from '../Alerts/ThopNotificationAlert/ThopNotificationAlert'
import './Sidebar.css'

const Sidebar = (): ReactElement => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const theme = useTheme()
  const sidebarButtonRef = useRef() as MutableRefObject<HTMLDivElement>
  const [activeButtonIndex, setActiveButtonIndex] = useState<number>(0)
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const openLayout = Boolean(anchorEl)
  const patientDetails = useSelector((state: any) => ({
    layout: state.layout,
  }))

  const handleLayoutButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget)
  }
  const handleLayoutClose = () => {
    setAnchorEl(null)
  }

  const setPatientDashboardLayout = () => {
    dispatch(setLayout('patientDashboard'))
    setAnchorEl(null)
  }

  const setVitalsLayout = () => {
    dispatch(setLayout('vitals'))
    setAnchorEl(null)
  }

  const setPatientDataLayout = () => {
    dispatch(setLayout('patientData'))
    setAnchorEl(null)
  }
  const setIntensivistsLayout = () => {
    dispatch(setLayout('intensivists'))
    setAnchorEl(null)
  }

  const handlePatientDataRoute = (index: number) => {
    if (activeButtonIndex !== null) {
      sidebarButtonRef?.current?.children?.[
        activeButtonIndex
      ]?.classList?.remove('sidebar-button-clicked')
    }
    sidebarButtonRef?.current?.children?.[index]?.classList?.add(
      'sidebar-button-clicked'
    )
    setActiveButtonIndex(index)
    navigate('/')
  }

  const handleAmbulanceRoute = (index: number) => {
    if (activeButtonIndex !== null) {
      sidebarButtonRef?.current?.children?.[
        activeButtonIndex
      ]?.classList?.remove('sidebar-button-clicked')
    }
    sidebarButtonRef?.current?.children?.[index]?.classList?.add(
      'sidebar-button-clicked'
    )
    setActiveButtonIndex(index)
    navigate('/ambulance')
  }

  // const handleHomeKareRoute = (index: number) => {
  //   if (activeButtonIndex !== null) {
  //     sidebarButtonRef?.current?.children?.[
  //       activeButtonIndex
  //     ]?.classList?.remove('sidebar-button-clicked')
  //   }
  //   sidebarButtonRef?.current?.children?.[index]?.classList?.add(
  //     'sidebar-button-clicked'
  //   )
  //   setActiveButtonIndex(index)
  //   navigate('/homekare')
  // }

  const handleFullScreen = () => {
    if (isFullScreen) {
      document.exitFullscreen()
    } else {
      document.body.requestFullscreen()
    }
    setIsFullScreen(!isFullScreen)
  }

  return (
    <div className='sidebar-container normal-mode' id='sidebar-container'>
      <Box className='logo' id='sidebar-logo' onClick={handleFullScreen}>
        <Tooltip
          id='screen-size-tooltip'
          title={isFullScreen ? exitFullscreen : enterFullScreen}
          arrow
        >
          {isFullScreen ? (
            <FullscreenExitIcon id='exit-fullscreen-icon' />
          ) : (
            <FullscreenIcon id='fullscreen-icon' />
          )}
        </Tooltip>
      </Box>
      <Box>
        <Box display='flex' flexDirection='column'>
          <Tooltip id='layout-tooltip' title='Select Layout' arrow>
            <IconButton
              size='large'
              id='layout-btn'
              aria-haspopup='true'
              aria-label='select-layout'
              aria-controls={openLayout ? 'basic-menu' : undefined}
              aria-expanded={openLayout ? 'true' : undefined}
              onClick={handleLayoutButtonClick}
            >
              <DashboardIcon className='layout-button' />
            </IconButton>
          </Tooltip>
          <Menu
            id='layout-menu'
            open={openLayout}
            anchorEl={anchorEl}
            onClose={handleLayoutClose}
          >
            <MenuItem
              id='patient-dashboard-layout'
              onClick={setPatientDashboardLayout}
            >
              {layout?.patientDashboard}
            </MenuItem>
            <MenuItem id='patient-vitals-layout' onClick={setVitalsLayout}>
              {layout?.patientVitals}
            </MenuItem>
            <MenuItem id='patient-data-layout' onClick={setPatientDataLayout}>
              {layout?.patientData}
            </MenuItem>
            <MenuItem id='intensivists-layout' onClick={setIntensivistsLayout}>
              {layout?.intensivists}
            </MenuItem>
          </Menu>
          <Tooltip id='video-tooltip' title='Video Call' arrow>
            <IconButton
              size='large'
              aria-haspopup='true'
              id='video-call-button'
              aria-label='start-video-call'
              onClick={() =>
                window.open('/videoCall', '_blank', 'rel=noopener noreferrer')
              }
            >
              <VideocamIcon className='layout-button' />
            </IconButton>
          </Tooltip>
        </Box>
        <div
          id='sidebar-buttons-group'
          className='sidebar'
          ref={sidebarButtonRef}
        >
          <Box
            id='hospitals-button'
            className='sidebar-button sidebar-button-clicked'
            onClick={() => handlePatientDataRoute(0)}
          >
            <Tooltip id='hospitals-tooltip' title='Hospitals' arrow>
              <LocalHospitalIcon id='hospitals-icon' />
            </Tooltip>
          </Box>
          <Box
            id='ambulance-button'
            className='sidebar-button'
            onClick={() => handleAmbulanceRoute(1)}
          >
            <Tooltip id='ambulance-tooltip' title='Ambulance' arrow>
              <AirportShuttleIcon id='ambulance-icon' />
            </Tooltip>
          </Box>
          {/* Required later, hence not removing */}
          {/* <Box
            id='homekare-button'
            className='sidebar-button'
            onClick={() => handleHomeKareRoute(2)}
          >
            <Tooltip id='homekare-tooltip' title='HomeKare' arrow>
              <HomeIcon id='homekare-icon' />
            </Tooltip>
          </Box> */}
        </div>
        {(patientDetails?.layout === sidebarPatientLayout?.vitals ||
          patientDetails?.layout === sidebarPatientLayout?.default) && (
          <Alerts />
        )}
        {(patientDetails?.layout === sidebarPatientLayout?.intensivists ||
          patientDetails?.layout === sidebarPatientLayout?.default) && (
          <ThopNotificationAlert />
        )}
      </Box>
      <div
        id='siderbar-bottom-buttons-group'
        className='sidebar-bottom-button-set'
      >
        <Box
          id='logout-dialog'
          className='sidebar-button'
          onClick={() => setOpenDialog(true)}
        >
          <Tooltip id='logout-tooltip' title='Logout' arrow>
            <ExitToAppIcon id='logout-icon' />
          </Tooltip>
        </Box>
        <Dialog
          id='logout-dialog-confirmation'
          open={openDialog}
          fullScreen={fullScreen}
          onClose={() => setOpenDialog(false)}
        >
          <DialogTitle>{sidebarTexts?.logoutConfirm}</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              {sidebarTexts?.currentlyLoggedIn}:&nbsp;
              <b>
                {sessionStorage.getItem('first-name')}
                &nbsp;{sessionStorage.getItem('last-name')}
              </b>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOpenDialog(false)}
              id='close-logout-dialog'
            >
              {sidebarTexts?.no}
            </Button>
            <Button
              onClick={() => KeycloakService?.doLogout()}
              autoFocus
              id='confirm-logout'
            >
              {sidebarTexts?.yes}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  )
}

export default Sidebar
