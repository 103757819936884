import { useDispatch, useSelector } from 'react-redux'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { WEBSOCKET_URL, ambulanceText } from '../../utils/constants'
import { CustomArrowIcon, generateRandomID } from '../../utils/helperFunctions'
import {
  lazy,
  useRef,
  Suspense,
  useEffect,
  ReactElement,
  MutableRefObject,
} from 'react'
import {
  setAmbulanceList,
  clearAmbulanceList,
  setCurrentLocation,
  setSelectedAmbulance,
  setIsAmbulanceClicked,
  setSelectedAmbulanceIndex,
} from '../../redux/actions'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import LoadingComp from '../LoadingComp/LoadingComp'
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined'
import siren from '../../assets/siren.png'
import './Ambulance.css'

const AmbulanceLiveData = lazy(
  () => import('./AmbulanceLiveData/AmbulanceLiveData')
)

const Ambulance = (): ReactElement => {
  const dispatch = useDispatch()
  const currentLocation = useSelector((state: any) => state.currentLocation)
  const onlyLocations = useSelector((state: any) => state.onlyLocations)
  const ambulanceIndex = useSelector(
    (state: any) => state.selectedAmbulanceIndex
  )
  const ambulanceList = useSelector((state: any) => state.ambulanceList)
  const prorithmSocketRef = useRef<WebSocket | null>(null)
  const ambulanceCardRef = useRef() as MutableRefObject<HTMLDivElement>
  const isClicked = useSelector((state: any) => state.isAmbulanceClicked)

  const handleSelectedAmbulance = (ambulance: string, index: number) => {
    if (ambulanceIndex !== null) {
      ambulanceCardRef?.current?.children?.[ambulanceIndex]?.classList?.remove(
        'device-card-selected'
      )
    }
    ambulanceCardRef?.current?.children?.[index]?.classList?.add(
      'device-card-selected'
    )
    dispatch(setSelectedAmbulance(ambulance))
    dispatch(setSelectedAmbulanceIndex(index))
    dispatch(setIsAmbulanceClicked(true))
  }

  const handleLocationChange = (e: SelectChangeEvent) => {
    dispatch(setCurrentLocation(e.target.value))
  }

  const createProrithmSocketConnection = () => {
    const randomDeviceId = generateRandomID(4)
    const randomPatientId = generateRandomID(5)
    const socket = new WebSocket(
      `${WEBSOCKET_URL}websocket?deviceId=prorythm_${randomDeviceId}&patientId=prorythm_${randomPatientId}`
    )
    prorithmSocketRef.current = socket

    socket.onopen = function (event) {
      console.log('WebSocket connection:', event.type)
    }

    socket.onmessage = function (event) {
      const message = JSON.parse(event.data)
      if (message) {
        const macAddress = message?.mac || message?.metadata?.mac
        if (macAddress) {
          dispatch(setAmbulanceList(macAddress))
        }
      }
    }

    socket.onerror = function (event) {
      console.error('WebSocket error observed:', event.type)
    }

    socket.onclose = function (event) {
      console.error('Websocket closure code:', event.code)
      if (event.code !== 1000 && event.code !== 1001) {
        console.error(
          'Websocket closed abnormally. Reconnecting to WebSocket server...'
        )
        createProrithmSocketConnection()
      }
    }
  }

  useEffect(() => {
    // Reset view on route change
    if (isClicked) {
      dispatch(setIsAmbulanceClicked(false))
    }

    createProrithmSocketConnection()

    return () => {
      prorithmSocketRef?.current?.close()
      dispatch(setSelectedAmbulanceIndex(null))
      dispatch(clearAmbulanceList())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div id='ambulance-details' className='ambulance-container'>
      <Grid container columns={16}>
        <Grid item xs={16} sm={7} md={4} lg={3} xl={2}>
          <Select
            id='ambulance-location'
            value={currentLocation}
            onChange={handleLocationChange}
            IconComponent={CustomArrowIcon}
            className='select-location'
          >
            {onlyLocations?.map((item) => (
              <MenuItem id='ambulance-location-item' value={item} key={item}>
                <div id='location-container' className='location-default-item'>
                  <FmdGoodOutlinedIcon className='location-icon' />
                  <span id='location-name' className='location-list-item'>
                    {item}
                  </span>
                </div>
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={16} sm={9} md={12} lg={13} xl={14}>
          <div
            id='ambulance-lists'
            className='ambulance-section'
            ref={ambulanceCardRef}
          >
            {ambulanceList?.length ? (
              <>
                {ambulanceList?.map((ambulance, index) => {
                  return (
                    <Box
                      id='ambulance-card-box'
                      key={ambulance}
                      className={`ambulance-card ${
                        index === ambulanceIndex
                          ? 'ambulance-card-selected'
                          : ''
                      }`}
                      onClick={() => handleSelectedAmbulance(ambulance, index)}
                    >
                      <div id='ambulance-icon-box' className='ambulance-image'>
                        <img
                          alt='ambulance'
                          src={siren}
                          height={30}
                          width={30}
                        />
                      </div>
                      <Box
                        id='ambulance-name'
                        typography='body2'
                        className='ambulance-name'
                      >
                        {ambulance}
                      </Box>
                    </Box>
                  )
                })}
              </>
            ) : (
              <div id='no-ambulances' className='no-ambulance-available'>
                {ambulanceText?.noAmbulanceAvailableText}
              </div>
            )}
          </div>
        </Grid>
      </Grid>
      {isClicked && (
        <Suspense fallback={<LoadingComp />}>
          <AmbulanceLiveData prorithmSocketRef={prorithmSocketRef?.current} />
        </Suspense>
      )}
    </div>
  )
}

export default Ambulance
