import { BrowserRouter as Router } from 'react-router-dom'
import KeycloakService from './utils/keycloakService'
import ReactDOM from 'react-dom/client'
import App from './App'
import './index.css'

const renderRoot = () => {
  const rootElement = document.getElementById('root') as HTMLElement
  if (rootElement) {
    const root = ReactDOM.createRoot(rootElement)
    root.render(
      // <React.StrictMode>
      <Router>
        <App />
      </Router>
      // </React.StrictMode>
    )
  } else {
    console.error('Root element not found')
  }
}

KeycloakService.initKeycloak(renderRoot)
