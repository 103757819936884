import Keycloak from 'keycloak-js'
import { AUTH_SERVER_URL, CLIENT_ID, CONSTANTS, REALM } from './constants'

const storeToken = (token: string | undefined | null): void => {
  sessionStorage.setItem(CONSTANTS.REACT_TOKEN, token ?? '')
}

const keycloak = new Keycloak({
  url: `${AUTH_SERVER_URL}auth`,
  realm: REALM,
  clientId: CLIENT_ID,
})

const initKeycloak = (onAuthenticatedCallback: () => void): void => {
  keycloak
    .init({
      onLoad: 'check-sso',
      checkLoginIframe: false,
      pkceMethod: 'S256',
    })
    .then((authenticated: any) => {
      if (authenticated) {
        const { token, refreshToken, idTokenParsed } = keycloak
        if (token && refreshToken && idTokenParsed) {
          sessionStorage.setItem(CONSTANTS.FIRST_NAME, idTokenParsed.given_name)
          sessionStorage.setItem(CONSTANTS.LAST_NAME, idTokenParsed.family_name)
          sessionStorage.setItem(CONSTANTS.USER_ID, idTokenParsed.sub ?? '')
          sessionStorage.setItem(CONSTANTS.REACT_TOKEN, token)
        }
        onAuthenticatedCallback()
      } else {
        doLogin()
      }
    })
    .catch((err) => {
      console.error(err)
    })
}

const doLogin = keycloak.login

const doLogout = keycloak.logout

const getToken = (): string | undefined => keycloak.token

const isLoggedIn = (): boolean => !!keycloak.token

const updateToken = (successCallback?: () => void): Promise<void> =>
  keycloak.updateToken(5).then(successCallback).catch(doLogin)

const getUsername = (): string | undefined =>
  keycloak.tokenParsed?.preferred_username

const getFullname = (): string =>
  (
    (keycloak.tokenParsed?.given_name ?? '') +
    ' ' +
    (keycloak.tokenParsed?.family_name ?? '')
  )?.trim()

const getSub = (): string | undefined => keycloak.tokenParsed?.sub

const getUserEmail = (): string | undefined => keycloak.tokenParsed?.email

const hasRole = (roles: string[]): boolean =>
  roles?.some((role: string) => keycloak.hasResourceRole(role))

const getLocation = (): string | undefined => keycloak.tokenParsed?.location

keycloak.onTokenExpired = (): void => {
  keycloak.updateToken(5)
}

keycloak.onAuthSuccess = (): void => {
  storeToken(keycloak.token)
}

keycloak.onAuthRefreshSuccess = (): void => {
  storeToken(keycloak.token)
}

keycloak.onAuthLogout = (): void => sessionStorage.clear()

const KeycloakService = {
  storeToken,
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  getFullname,
  getSub,
  getUserEmail,
  hasRole,
  getLocation,
}

export default KeycloakService
