import { useDispatch, useSelector } from 'react-redux'
import { CustomArrowIcon } from '../../utils/helperFunctions'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { patientList, ambulanceText } from '../../utils/constants'
import { MutableRefObject, ReactElement, useEffect, useRef } from 'react'
import {
  setCurrentLocation,
  setIsAmbulanceClicked,
  setSelectedAmbulanceIndex,
} from '../../redux/actions'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import PersonIcon from '@mui/icons-material/Person'
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined'
// import AmbulanceLiveData from '../Ambulance/AmbulanceLiveData/AmbulanceLiveData'
import './HomeKare.css'

const HomeKare = (): ReactElement => {
  const currentLocation = useSelector((state: any) => state.currentLocation)
  const onlyLocations = useSelector((state: any) => state.onlyLocations)
  const dispatch = useDispatch()
  const ambulanceIndex = useSelector(
    (state: any) => state.selectedAmbulanceIndex
  )

  const isClicked = useSelector((state: any) => state.isAmbulanceClicked)
  const ambulanceCardRef = useRef() as MutableRefObject<HTMLDivElement>

  const handleSelectedAmbulance = (index: number) => {
    if (ambulanceIndex !== null) {
      ambulanceCardRef?.current?.children?.[ambulanceIndex]?.classList?.remove(
        'device-card-selected'
      )
    }
    ambulanceCardRef?.current?.children?.[index]?.classList?.add(
      'device-card-selected'
    )

    dispatch(setSelectedAmbulanceIndex(index))
    dispatch(setIsAmbulanceClicked(true))
  }

  const handleLocationChange = (e: SelectChangeEvent) => {
    dispatch(setCurrentLocation(e.target.value))
  }

  useEffect(() => {
    // Reset view on route change
    if (isClicked) {
      dispatch(setIsAmbulanceClicked(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='ambulance-container'>
      <Grid container columns={16}>
        <Grid item xs={16} sm={7} md={4} lg={3} xl={2}>
          <Select
            value={currentLocation}
            onChange={handleLocationChange}
            IconComponent={CustomArrowIcon}
            className='select-location'
          >
            {onlyLocations?.map((item) => (
              <MenuItem value={item} key={item}>
                <div className='location-default-item'>
                  <FmdGoodOutlinedIcon className='location-icon' />
                  <span className='location-list-item'>{item}</span>
                </div>
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={16} sm={9} md={12} lg={13} xl={14}>
          <div className='device-section' ref={ambulanceCardRef}>
            {patientList?.length ? (
              <>
                {patientList?.map((patient, index) => {
                  return (
                    <Box
                      key={patient?.patientId}
                      className={`device-card ${
                        index === ambulanceIndex ? 'device-card-selected' : ''
                      }`}
                      onClick={() => handleSelectedAmbulance(index)}
                    >
                      <div className='device-image'>
                        <PersonIcon className='device-logo' fontSize='large' />
                      </div>
                      <div>
                        <Box typography='body2'>{patient?.patientName}</Box>
                      </div>
                    </Box>
                  )
                })}
              </>
            ) : (
              <div className='no-ambulance-available'>
                {ambulanceText?.noAmbulanceAvailableText}
              </div>
            )}
          </div>
        </Grid>
      </Grid>
      {/* {isClicked && <AmbulanceLiveData />} */}
    </div>
  )
}

export default HomeKare
