import { historyVitals } from '../../utils/constants'
import { ReactElement, Suspense, lazy, useState } from 'react'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import LoadingComp from '../LoadingComp/LoadingComp'
import FormControlLabel from '@mui/material/FormControlLabel'
import './VitalsContainer.css'

const HistoryCharts = lazy(() => import('../HistoryCharts/HistoryCharts'))
const PatientVitals = lazy(() => import('../PatientVitals/PatientVitals'))

const VitalsContainer = (): ReactElement => {
  const [isHistoryChecked, setIsHistoryChecked] = useState<boolean>(false)

  return (
    <>
      <div className='history-options'>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                id='history-checkbox'
                value={isHistoryChecked}
                onChange={() => setIsHistoryChecked(!isHistoryChecked)}
              />
            }
            label='History'
          />
        </FormGroup>
        {isHistoryChecked ? (
          <div className='duration-interval' id='selected-interval'>
            {historyVitals?.eightHoursInterval}
          </div>
        ) : null}
      </div>
      <Suspense fallback={<LoadingComp />}>
        {isHistoryChecked ? <HistoryCharts /> : <PatientVitals />}
      </Suspense>
    </>
  )
}

export default VitalsContainer
