import { Route, Routes } from 'react-router-dom'
import { ReactElement, Suspense, lazy } from 'react'
import HomeKare from '../HomeKare/HomeKare'
import Hospitals from '../Hospitals/Hospitals'
import Ambulance from '../Ambulance/Ambulance'
import LoadingComp from '../LoadingComp/LoadingComp'

const PatientVideo = lazy(() => import('../PatientVideo/PatientVideo'))

const Navigator = (): ReactElement => {
  return (
    <Routes>
      <Route path='/' element={<Hospitals />} />
      <Route path='/ambulance' element={<Ambulance />} />
      <Route path='/homekare' element={<HomeKare />} />
      <Route
        path='/videoCall'
        element={
          <Suspense fallback={<LoadingComp />}>
            <PatientVideo />
          </Suspense>
        }
      />
    </Routes>
  )
}

export default Navigator
