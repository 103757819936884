import { useEffect, useState } from 'react'
import { CriticalBeds } from '../interfaces'
import { createApiUrl } from './helperFunctions'
import { CRITICAL_BEDS_ID, intent } from './constants'

const useGetCriticalBeds = (location: string): CriticalBeds[] => {
  const [criticalBedList, setCriticalBedList] = useState<CriticalBeds[]>([])

  function fetchCriticalBeds() {
    const payload = {
      intent: intent?.getCriticalBedsByLocation,
      state: { locationId: location },
    }
    fetch(createApiUrl(CRITICAL_BEDS_ID), {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('react-token')}`,
        'Content-Type': 'application/json',
        version: 'WORKING',
      },
      body: JSON.stringify(payload),
    })
      .then((res) => {
        if (!res?.ok) {
          setCriticalBedList([])
          throw new Error('Failed to get critical beds')
        }
        return res?.json()
      })
      .then((data) => setCriticalBedList(data?.bed))
      .catch((err) => console.error(err))
  }

  useEffect(() => {
    fetchCriticalBeds()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return criticalBedList
}

export default useGetCriticalBeds
