import { configureStore } from '@reduxjs/toolkit'
import { loadingActionReducer } from './reducer'
import {
  LAYOUT,
  THOP_NOTIFICATION_MESSAGES_ADD,
  STATUS_UPDATE_COUNT,
} from './actions'
import {
  createStateSyncMiddleware,
  initMessageListener,
} from 'redux-state-sync'

const reduxStateSyncConfig = {
  blacklist: [LAYOUT, THOP_NOTIFICATION_MESSAGES_ADD, STATUS_UPDATE_COUNT],
}

const stateSyncMiddleware = createStateSyncMiddleware(reduxStateSyncConfig)

const store = configureStore({
  reducer: loadingActionReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      stateSyncMiddleware
    ),
})

initMessageListener(store)

export { store }
