import { useDispatch } from 'react-redux'
import { setDoctors } from '../redux/actions'
import { createApiUrl } from './helperFunctions'
import { CHAT_PROJECT_ID, intent } from './constants'

const useGetDoctors = (): { fetchGetDoctors: () => void } => {
  const dispatch = useDispatch()

  function fetchGetDoctors() {
    const payload = {
      intent: intent?.getDoctorsAPI,
    }

    fetch(createApiUrl(CHAT_PROJECT_ID), {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('react-token')}`,
        'Content-Type': 'application/json',
        version: 'WORKING',
      },
      body: JSON.stringify(payload),
    })
      .then((res) => {
        if (!res?.ok) throw new Error('Failed to get doctors')
        return res?.json()
      })
      .then((data) => {
        const doctorsNamesList = data?.entry
          ?.map((item, index) => {
            const name = item?.resource?.name?.[0]
            if (name?.given && name?.family) {
              return {
                id: index,
                name: `${name?.given?.join(' ')} ${name?.family}`,
              }
            }
            return null
          })
          ?.filter(Boolean)

        if (doctorsNamesList?.length > 0) {
          dispatch(setDoctors(doctorsNamesList))
        }
      })
      .catch((err) => console.error(err))
  }

  return { fetchGetDoctors }
}

export default useGetDoctors
