import { CriticalBeds } from '../interfaces'
import { CRITICAL_BEDS_ID, intent } from './constants'
import { useDispatch, useSelector } from 'react-redux'
import { setMasterPatientDetails } from '../redux/actions'
import { createApiUrl, titleCase } from './helperFunctions'

const useGetAllBeds = (): { fetchAllBeds: () => Promise<void> } => {
  const dispatch = useDispatch()
  const patientDetails = useSelector((state: any) => ({
    masterPatientDetails: state.masterPatientDetails,
    onlyLocations: state.onlyLocations,
  }))

  const fetchAllBeds = async (): Promise<void> => {
    if (patientDetails?.masterPatientDetails?.length) return
    patientDetails?.onlyLocations?.map(async (location) => {
      try {
        const payload = {
          intent: intent?.getCriticalBedsByLocation,
          state: { locationId: location },
        }
        const response = await fetch(createApiUrl(CRITICAL_BEDS_ID), {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('react-token')}`,
            'Content-Type': 'application/json',
            version: 'WORKING',
          },
          body: JSON.stringify(payload),
        })
        if (!response?.ok) {
          throw new Error('Error fetching critical beds for all locations')
        }
        const data = await response?.json()
        const patientData = data?.bed?.map((item: CriticalBeds) => {
          return {
            patientId: item?.patient_id,
            patientName: titleCase(item?.patient_name),
            location: item?.location_id,
          }
        })
        dispatch(setMasterPatientDetails(patientData))
      } catch (error) {
        console.error(error)
      }
    })
  }

  return { fetchAllBeds }
}

export default useGetAllBeds
