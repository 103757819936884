import { useDispatch } from 'react-redux'
import { createApiUrl } from './helperFunctions'
import { CRITICAL_BEDS_ID, intent } from './constants'
import {
  setPatientDOB,
  setPatientName,
  setPatientUHID,
  setPatientGender,
  setPatientFhirId,
  setPatientWardName,
  setPatientBedNumber,
  setPatientRoomNumber,
  setHasPatientDataLoaded,
} from '../redux/actions'

const useGetPatientDetails = (): { fetchPatientDetails: (string) => void } => {
  const dispatch = useDispatch()

  function fetchPatientDetails(patientId: string) {
    const payload = {
      intent: intent?.getPatientDetails,
      state: {
        patientIdentifier: patientId,
      },
    }
    fetch(createApiUrl(CRITICAL_BEDS_ID), {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('react-token')}`,
        'Content-Type': 'application/json',
        version: 'WORKING',
      },
      body: JSON.stringify(payload),
    })
      .then((res) => {
        if (!res?.ok) throw new Error('Failed to get patient details.')
        return res?.json()
      })
      .then((data) => {
        dispatch(
          setPatientName(
            data?.firstName + ' ' + data?.middleName + ' ' + data?.lastName
          )
        )
        dispatch(setPatientUHID(data?.uhid))
        dispatch(setPatientDOB(data?.dateOfBirth))
        dispatch(setPatientGender(data?.gender))
        dispatch(setPatientFhirId(data?.fhirId))
        dispatch(setPatientBedNumber(data?.bed_number))
        dispatch(setPatientRoomNumber(data?.room_number))
        dispatch(setPatientWardName(data?.ward_name))
        dispatch(setHasPatientDataLoaded(true))
      })
      .catch((err) =>
        console.error('Error communicating with server. Error code: ', err)
      )
  }
  return { fetchPatientDetails }
}

export default useGetPatientDetails
