import { ReactElement, Suspense, lazy, useState } from 'react'
import LoadingComp from '../LoadingComp/LoadingComp'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import './PatientData.css'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index } = props
  return (
    <div
      id='custom-tab-panel'
      hidden={value !== index}
      className='custom-tab-panel'
    >
      {value === index && <>{children}</>}
    </div>
  )
}

const components = [
  {
    key: 'labReports',
    label: 'Lab Reports',
    component: lazy(() => import('./LabReports/LabReports')),
  },
  {
    key: 'medications',
    label: 'Medications',
    component: lazy(() => import('./Medications/Medications')),
  },
  {
    key: 'diagnosis',
    label: 'Diagnosis',
    component: lazy(() => import('./Diagnosis/Diagnosis')),
  },
  {
    key: 'treatmentPlan',
    label: 'Treatment Plan',
    component: lazy(() => import('./TreatmentPlan/TreatmentPlan')),
  },
  {
    key: 'initialAssesment',
    label: 'Initial Assesment',
    component: lazy(() => import('./InitialAssesment/InitialAssesment')),
  },
  {
    key: 'medicationAdministration',
    label: 'Medication Administration',
    component: lazy(
      () => import('./MedicationAdministration/MedicationAdministration')
    ),
  },
]

const PatientData = (): ReactElement => {
  const [reportsTabValue, setReportsTabValue] = useState<number>(0)

  const handleReportsTabChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setReportsTabValue(newValue)
  }

  return (
    <div id='patient-reports-container' className='patient-reports'>
      <Tabs
        variant='scrollable'
        value={reportsTabValue}
        className='styled-custom-tabs'
        onChange={handleReportsTabChange}
        classes={{ indicator: 'custom-indicator' }}
      >
        {components?.map((tab) => (
          <Tab className='styled-tab' key={tab?.key} label={tab?.label} />
        ))}
      </Tabs>
      {components?.map((tab, index) => (
        <CustomTabPanel key={tab?.key} value={reportsTabValue} index={index}>
          <Suspense fallback={<LoadingComp />}>
            <tab.component />
          </Suspense>
        </CustomTabPanel>
      ))}
    </div>
  )
}

export default PatientData
