import { ReactElement } from 'react'
import { LifeLine } from 'react-loading-indicators'
import './LoadingComp.css'

const LoadingComp = (): ReactElement => {
  return (
    <div className='loading-container'>
      <LifeLine color='#004c91' size='large' />
    </div>
  )
}

export default LoadingComp
